@font-face {
  font-family: "Rock Salt";
  src: url(../Fonts/RockSalt-Regular.ttf);
  font-display: swap;
}

.main-header{
    text-align: center;
    font-family: 'Rock Salt', cursive;
    color: hsl(0, 0%, 96%);
    background-color: hsl(0, 2%, 13%);
    padding: .75rem;
    font-size: 1.6em;
}

@media only screen and (min-width: 600px) {
  .main-header{
    font-size: 2em;
  }
}