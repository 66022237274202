@font-face {
  font-family: "Abril Fatface";
  src: url(../Fonts/AbrilFatface-Regular.ttf);
  font-display: swap;
}

.landing-main {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.landing-link {
  display: block;
  font-family: "Abril Fatface", "Times New Roman", Times, serif;
  font-size: 12vw;
  color: black;
  text-decoration: none;
  text-align: center;
}

.landing-link:hover{
  color: hsl(0, 83%, 47%);
}

.bw-author{
  width: 80vw;
  padding-top: 15vw;
}

.landing-nav{
  padding-bottom: 15vw;
}

@media only screen and (min-width: 600px) {
  .bw-author {
    width: 55vw;
    padding-top: 7vw;
  }

  .landing-nav{
    padding-bottom: 10vw;
  }

  .landing-link{
    font-size: 10vw;
  }
}

@media only screen and (min-width: 1000px) {
  .landing-main {
    padding-top: 7vw;
    padding-bottom: 7vw;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-evenly;
  }

  .landing-nav{
    padding-bottom: 0;
    margin-bottom: -8vw;
  }

  .landing-link {
    font-size: 7vw;
    text-align: left;
  }

  .bw-author {
    width: 40vw;
  }
}
